import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterclassService {

  private selectedMasterClassSubject = new BehaviorSubject<any>(null);
  selectedMasterClass$ = this.selectedMasterClassSubject.asObservable();


  private selectedDetailsSource = new BehaviorSubject<any>(null); 
  selectedDetails$ = this.selectedDetailsSource.asObservable();


  private selectedTab = new BehaviorSubject<any>(null);
  selectedTab$ = this.selectedTab.asObservable();

  private selectedIdSource = new BehaviorSubject<number | null>(null);
  selectedId$ = this.selectedIdSource.asObservable();


  private videoUrlSource = new BehaviorSubject<string | null>(null);
  videoUrl$ = this.videoUrlSource.asObservable();


  private videoTrailerSource = new BehaviorSubject<string | null>(null);
  videoTrailer$ = this.videoTrailerSource.asObservable();


  private videoSampleSource = new BehaviorSubject<string | null>(null);
  videoSample$ = this.videoSampleSource.asObservable();


  Url = `${environment.apiUrl}`;
  userToken = localStorage.getItem('master_token');
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'skip': 'true',
      'Authorization': this.userToken
    })
  };

  constructor(private http: HttpClient) {

  }

  setTrailerVideo(url: string){
    this.videoTrailerSource.next(url);
  }

  setSampleVideo(url: string){
    this.videoSampleSource.next(url);
  }

  setVideoUrl(url: string): void {
    this.videoUrlSource.next(url); 
  }

  setSelectedId(id: number) {
    this.selectedIdSource.next(id);
  }
  
  setSelectedMasterClass(masterClass: any) {
    this.selectedMasterClassSubject.next(masterClass);
  }

  updateSelectedDetails(details: any) {
    this.selectedDetailsSource.next(details);
  }

  selectedTabFlag(seletedTab: any){
    this.selectedTab.next(seletedTab);
  }

  getToken():Observable<any>{
   let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'skip': 'true',
        'Authorization': localStorage.getItem(environment.authTokenKey)
      })
    };
    return this.http.get(this.Url + 'updateToken', httpOptions)
  }


  updateStudent():Observable<any>{
    let httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json',
         'skip': 'true',
         'Authorization': localStorage.getItem(environment.authTokenKey)
       })
     };
     return this.http.get(this.Url + 'studentMasterclass', httpOptions)
   }

  getMasterClasses(lms_subject_id:number, lms_topic_id:number):Observable<any>{
    let params = new HttpParams();
    if (lms_subject_id) {
      params = params.set('lms_subject_id', lms_subject_id.toString());
    }
    if (lms_topic_id) {
      params = params.set('lms_topic_id', lms_topic_id);
    }

    // const headers = new HttpHeaders({
    //   'token': 'CustomHeaderValue',
    //   'skip': 'true',
    //   'Authorization': 'Bearer your-token-here'
    // });

    return this.http.get("https://masterclass-dev.miles-api.com/api/masterclass", { params, ...this.getAuth() });
  }



  getSelectedDetails(id: number): Observable<any> {
    const url = `https://masterclass-dev.miles-api.com/api/masterclass/details?id=${id}`;
    return this.http.get(url, this.getAuth());
  }
  

  newlyAdded(value){
    const url = `https://masterclass-dev.miles-api.com/api/masterclass?masterclassassignment__enable_newly_added=${value}`;
    return this.http.get(url, this.getAuth());
  }


  personal(){
    const url = `https://masterclass-dev.miles-api.com/api/masterclass`;
    return this.http.get(url, this.getAuth());
  }


  popular(value){
    const url = `https://masterclass-dev.miles-api.com/api/masterclass?masterclassassignment__enable_highlight=${value}`;
    return this.http.get(url, this.getAuth());
  }


  author(id){
    const url = `https://masterclass-dev.miles-api.com/api/masterclass?host_instructor_id=${id}`;
    return this.http.get(url, this.getAuth());
  }


  courseMasterClass(id){
    const url = `https://masterclass-dev.miles-api.com/api/masterclass?category_id=${id}`;
    return this.http.get(url, this.getAuth());
  }




  
  getAuth() {
   return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'skip': 'true',
        'Authorization': this.userToken
      })
    };
  }

}
