<div class="modal-header py-2">
  <button type="button" class="close pull-right py-1" aria-label="Close" (click)="modalRef.hide();"
    style="margin-top: 0px;">
    <i class="fas fa-times"></i>
  </button>
  <span class="modal-title w-100" id="myModalLabel" style="font-weight: normal;font-size: 1.3rem !important;">Content
    Updates</span>
</div>
<div class="modal-body" style="background: #ffffff;">
  <table class="table table-bordered content_updates_table mb-3">
    <thead>
      <tr>
        <th colspan="4">If you are using the 2024 edition Miles Concept Notes, refer updates below
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr>
        <td width="40%">Audit (AUD)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q32023_Updatev0.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR2023_Q22023_Final.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr> -->
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">
            <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/content-updates/FAR2025Q1_v3.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> FAR Updates Q1-2025 – Effective Jan 1, 2025
            </a>
            <span class="blinking">New</span>
            <a (click)="farModal1.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
              style="background-color: #E63946 !important; box-shadow: none;">
              <i class="fas fa-play"></i> FAR Q1 2025 Content update overview
            </a>
            <span class="blinking">New</span>
            <a (click)="farModal2.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
              style="background-color: #E63946 !important; box-shadow: none;">
              <i class="fas fa-play"></i> Enhanced disclosures for Income Tax Expense
            </a>
            <span class="blinking">New</span>
            <a (click)="farModal3.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
              style="background-color: #E63946 !important; box-shadow: none;">
              <i class="fas fa-play"></i> Accounting for crypto assets
            </a>
            <span class="blinking">New</span>
            <a (click)="farModal4.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
            style="background-color: #E63946 !important; box-shadow: none;">
            <i class="fas fa-play"></i> Accounting of credit losses under CECL model
            </a>
            <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Auditing (AUD)</td>
        <td width="60%">
          <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/content-updates/AUD2025Q12025_v3.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD Updates Q1-2025 – Effective Jan 1, 2025
          </a>
          <span class="blinking">New</span>
          <a (click)="audModal1.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
              style="background-color: #E63946 !important; box-shadow: none;">
              <i class="fas fa-play"></i> AUD Q1 2025 Content Update overview
            </a>
            <span class="blinking">New</span>
            <a (click)="audModal2.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
              style="background-color: #E63946 !important; box-shadow: none;">
              <i class="fas fa-play"></i> AUD 6.4 Audit of Group FS (PCAOB AS 1206)
            </a>
            <span class="blinking">New</span>
            <a (click)="audModal3.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
              style="background-color: #E63946 !important; box-shadow: none;">
              <i class="fas fa-play"></i> AUD 2.4 Audit Supervision (PCAOB AS 1201)
            </a>
            <span class="blinking">New</span>
            <a (click)="audModal4.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
            style="background-color: #E63946 !important; box-shadow: none;">
            <i class="fas fa-play"></i> AUD 1.3 Content Update (PCAOB AS 1000)
            </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Regulation (REG)</td>
        <td width="60%">
          <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/content-updates/REGQ12025updates.pdf"
          target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
          <i class="fas fa-download"></i> REG updates Q1-2025 – Effective Jan 1, 2025
          </a>
          <span class="blinking">New</span>
          <!-- <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/content-updates/REG_Q3_2024updates_v1.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> REG Content Update Q3 2024
          </a> -->
         
          <!-- <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2023_updates_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a> -->
          
        </td>
      </tr>
      <tr>
        <td width="40%">Business (BAR)</td>
        <td width="60%">
          <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/content-updates/BARQ12025_v1.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BAR Updates Q1-2025 – Effective Jan 1, 2025
          </a>
          <a (click)="farModal3.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
          style="background-color: #E63946 !important; box-shadow: none;">
          <i class="fas fa-play"></i> Accounting for crypto assets
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
    </tbody>

  </table>
  <!-- <table class="table table-bordered content_updates_table mb-3">
    <thead>
      <tr>
        <th colspan="4">If you are using the 2022 edition Miles Concept Notes, refer updates below
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td width="40%">Auditing (AUD)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD_Q3_2022_Updates.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.2
          </a>
          <a (click)="audModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
            style="background-color: #E63946 !important; box-shadow: none;">
            <i class="fas fa-play"></i> AUD-7.2
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q1_2023_UPDATES.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q1 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-6.5_Errata.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-6.5 Errata
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-4.1(I).pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-4.1 (I)
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q32023_Updatev0.pdf"
                 target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
        </td>
      </tr>
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR_Updates_Q3_2022.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a (click)="farModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
            style="background-color: #E63946 !important; box-shadow: none;">
            <i class="fas fa-play"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR2023_Q22023_Final.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Regulation (REG)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2022.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/REGUpdates-Q2_2023_Final_v1.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2023_updates_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
    </tbody>

  </table>
  <table class="table table-bordered content_updates_table mb-3">
    <thead>
      <tr>
        <th colspan="4">If you are using the 2021 edition Miles Concept Notes, refer updates below
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td width="40%">Auditing (AUD)</td>
        <td width="60%">
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_4_1_Appendix_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-4.1
            </a> 
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-4.1_(I).pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-4.1 (I)
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.4
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4%20_Additonal_Jul_1_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-7.4 - Additional MCQs
            </a> 
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_6_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.6
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_July_2021_Updates.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> July 2021 – Key Updates
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_Q42021_Updates_Errata.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>Q4 2021
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-6_2022_Reporting_Model.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-6
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-3.4_2022.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-3.4-Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-7.7_2022.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-7.7
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD_Q12022_Updates.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>Q1 2022
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD_Q3_2022_Updates.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.2
          </a>
          <a (click)="audModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
            style="background-color: #E63946 !important; box-shadow: none;">
            <i class="fas fa-play"></i> AUD-7.2
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q1_2023_UPDATES.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q1 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-6.5_Errata.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-6.5 Errata
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q32023_Updatev0.pdf"
                 target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Business (BEC)</td>
        <td width="60%">
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_July_2021_Updates.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> July 2021 – Key updates
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_1_2_Business_Processes_(Operating%20cycle).pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-1.2 MCQ's
            </a>
            <span class="blinking">New</span> 
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_1_2_Appendix_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-1.2
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_9_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.9
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_9_Data_management_and_relationships.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.9 MCQ's
            </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_10_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.10
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_10_Outsourced_IT_Functions.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.10 MCQ's
            </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_11_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.11
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_11_Change_Management.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.11 MCQ's
            </a>
            <span class="blinking">New</span>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_12_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.12
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_12_Resilience.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.12 MCQ's
            </a>
              <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">

          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_July_2021_Updates.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> July 2021 – Key Updates
          </a>

          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR-4_5_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> FAR-4.5
          </a>

          <a href="https://streaming.mileseducation.com/cpa_ebooks/FAR_2022_Errata.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> FAR Errata 2022
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR_Updates_Q3_2022.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a (click)="farModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn"
            style="background-color: #E63946 !important; box-shadow: none;">
            <i class="fas fa-play"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR2023_Q22023_Final.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <span class="blinking">New</span>

          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_4_5_Additional_MCQs.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> FAR 4.5 - Additional MCQs
            </a>
            <span class="blinking">New</span> 
        </td>
      </tr>
      <tr>
        <td width="40%">Regulation (REG)</td>
        <td width="60%">
           <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2020_CARES_Act.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> CARES Act Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q3_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> Q3 2021 Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q4 2021 Updates
            </a> 
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/CPA_Exam_Blueprint%20Changes_effective.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>CPA Exam Blueprint Changes effective Jul 1, 2021
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2022.pdf" target="_blank"
            class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/REGUpdates-Q2_2023_Final_v1.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn_miles_secondary btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2023_updates_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
    </tbody>

  </table> -->

</div>

<!-- AUD Video Modal -->
<div mdbModal #audModal1="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="audModal1.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">AUD Q1 2025 Content Update overview</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/T4FpR0coQG0" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mdbModal #audModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="audModal2.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">AUD 6.4 Audit of Group FS (PCAOB AS 1206)</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/HPWumZEm6Us" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mdbModal #audModal3="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="audModal3.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">AUD 2.4 Audit Supervision (PCAOB AS 1201)</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/dNa_O-dJhuE" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mdbModal #audModal4="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="audModal4.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">AUD 1.3 Content Update (PCAOB AS 1000)</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/mlUzHEUh68c" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<!-- FAR Video Modal -->
<div mdbModal #farModal1="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="farModal1.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">FAR Q1 2025 Content update overview</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/yYS0fDYcfyw" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mdbModal #farModal2="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="farModal2.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">Enhanced disclosures for Income Tax Expense</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/V7HqA9D2apA" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mdbModal #farModal3="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="farModal3.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">Accounting for crypto assets</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/u8eCZDbq1Fo" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div mdbModal #farModal4="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="farModal4.hide()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">Accounting of credit losses under CECL model</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/eI86NN8MNHY" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>