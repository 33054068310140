<nav class="desktop_sidebar" [ngClass]="hideSideBar ? 'd-none': ''">
  <div class="sidebar-top">
    <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/logo.png" class="logo" alt="">
  </div>
  <div style="position: relative; margin-top: 35px;">
    <div class="top_header" [ngClass]="{'active-class': activeDiv == 1}">
      <div class="right_menu" [ngClass]="{'hidden': activeDiv !== 1}" [@fadeInOut] *ngIf="activeDiv === 1">
        <ul class="align-items-center " [ngClass]="{'menu-showless': activeDiv == 1}">

        <ng-container *ngFor="let forumData of forumLaunchpadData">
          <li *ngIf="userData.is_forum_admin == 1 && forumData.int_subject_id == 101">
            <a mdbTooltip="AUD" placement="top" (click)="navigate('/lms/forum/subject/c53f9b7df8fbb22dc12d0e859a5c9734')">
              <span class="top_header_icons" style="color: #fff;">{{forumData.activetickets_count}}</span>
            </a>
          </li>
          <li *ngIf="userData.is_forum_admin == 1 && forumData.int_subject_id == 110">
            <a mdbTooltip="BAR" placement="top" (click)="navigate('/lms/forum/subject/e3cabe6023121c56d1ffa9a8b12e100a')">
              <span class="top_header_icons" style="color: #fff;">{{forumData.activetickets_count}}</span>
            </a>
          </li>
          <li *ngIf="userData.is_forum_admin == 1 && forumData.int_subject_id == 103">
            <a mdbTooltip="FAR" placement="top" (click)="navigate('/lms/forum/subject/a1a8cddccb57f50303507da5087ed062')" routerLinkActive="active">
              <span class="top_header_icons" style="color: #fff;">{{forumData.activetickets_count}}</span>
            </a>
          </li>

          <li *ngIf="userData.is_forum_admin == 1 && forumData.int_subject_id == 104">
            <a mdbTooltip="REG" placement="top" (click)="navigate('/lms/forum/subject/5fdf99246bcbd70b7fdb6b6b600e6d35')" routerLinkActive="active">
              <span class="top_header_icons" style="color: #fff;">{{forumData.activetickets_count}}</span>
            </a>
          </li>
        </ng-container>
          <!-- <li *ngIf="userData.is_forum_admin == 1">
            <a mdbTooltip="B.E.C" placement="top" (click)="navigate('/lms/forum/subject/53625d62402e139ccc1e441dea96b90c')">
              <span class="top_header_icons" style="color: #fff;">{{forumLaunchpadData[1]?.activetickets_count}}</span>
            </a>
          </li> -->

          <!-- userData.is_admin != 1&& -->
          <!-- <li *ngIf="userData.is_forum_admin != 1">
            <a mdbTooltip="Attendance" placement="top" [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/mileage/attendance'" routerLinkActive="active">
              <span class="top_header_icons icon_3"></span>
            </a>
          </li> -->
          <li *ngIf="userData.is_forum_admin != 1">
            <!-- [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/book-delivery'" routerLinkActive="active" -->
              <!-- (click)="showbooksNotification()" -->
            <a mdbTooltip="Order Book" placement="top" [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/book-delivery'" routerLinkActive="active">
              <span class="top_header_icons">
                <img src="/assets/svg/Order-Book.svg" alt="Menu Icons">
              </span>
            </a>
          </li>
          <li *ngIf="userData.is_forum_admin != 1">
            <a mdbTooltip="Notification" placement="top" (click)="notifiCation(); showAttentionPopup()" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_11"></span> -->
              <!-- <span class="fas fa-bell icon_11 ng-tns-c283-0 fa-normal" aria-hidden="true"></span> -->
              <span class="top_header_icons">
                <img src="/assets/svg/Notification.svg" alt="Menu Icons">
                <div *ngIf="notificationData.length > 0" class="notification_txt">{{notificationData.length}}</div>
              </span>
            </a>
            <div
            class="dropdown-menu dropdown-primary dropdown-menu-right p-0 notification-block"
            role="menu"
            style="border: 2px solid #c3c6ca; min-width: 20rem">
         <div
              *ngIf="notificationData.length"
              class="notification-header font-weight-bold d-flex justify-content-between">
           <span class="my-auto">NEW</span>
           <button
                   size="sm"
                   color="link"
                   mdbBtn
                   mdbWavesEffects
                   class="m-0 font-weight-bold"
                   (click)="marksNotificaionsread(0)"
                   style="font-size: 11px; padding: 0.2rem 0.5rem; color: #2d6fdc !important">
             Clear all
           </button>
         </div>
         <ng-container *ngIf="notificationData.length">
           <ng-container *ngFor="let row of notificationData">
             <ng-container *ngIf="row.is_post_closed === 0">
               <div class="alert alert-info mb-0" role="alert" *ngIf="row.is_forum_admin === 0">
                 <a
                    (click)="
                     marksNotificaionsread(row.int_post_id);
                     goToForum(
                       row.int_thread_type_id,
                       row.int_subject_id,
                       row.txt_question_id,
                       row.int_topic_id,
                       row.int_subtopic_id,
                       row.int_video_id
                     )
                   ">
                   <div class="pr-2">
                     <i
                        class="fad"
                        [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-alt-lines' : 'fa-comments-alt'"
                        style="font-size: 1.2rem"></i>
                   </div>
                   <div *ngIf="row.is_post_r_reply === 1">
                     <b>{{ row.txt_name }}</b> replied to your
                     <b>{{
                       row.int_thread_type_id == 1
                       ? 'mcq'
                       : row.int_thread_type_id == 2
                       ? 'sim'
                       : row.int_thread_type_id == 3
                       ? 'video'
                       : row.int_thread_type_id == 4
                       ? 'ebook'
                       : ''
                       }}</b>
                     post
                   </div>
                   <div *ngIf="row.is_post_r_reply != 1">
                     <b>{{ row.txt_name }}</b> replied to your reply on
                     <b>{{
                       row.int_thread_type_id == 1
                       ? 'mcq'
                       : row.int_thread_type_id == 2
                       ? 'sim'
                       : row.int_thread_type_id == 3
                       ? 'video'
                       : row.int_thread_type_id == 4
                       ? 'ebook'
                       : ''
                       }}</b>
                     post
                   </div>
                 </a>
               </div>
               <div class="alert alert-primary mb-0" role="alert" *ngIf="row.is_forum_admin === 1">
                 <a
                    (click)="
                     marksNotificaionsread(row.int_post_id);
                     goToForum(
                       row.int_thread_type_id,
                       row.int_subject_id,
                       row.txt_question_id,
                       row.int_topic_id,
                       row.int_subtopic_id,
                       row.int_video_id
                     )
                   ">
                   <div class="pr-2">
                     <i
                        class="fad"
                        [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-lines' : 'fa-comments'"
                        style="font-size: 1.2rem"></i>
                   </div>
                   <div *ngIf="row.is_post_r_reply === 1">
                     <b>{{ row.txt_name }}</b> replied to your
                     <b>{{
                       row.int_thread_type_id == 1
                       ? 'mcq'
                       : row.int_thread_type_id == 2
                       ? 'sim'
                       : row.int_thread_type_id == 3
                       ? 'video'
                       : row.int_thread_type_id == 4
                       ? 'ebook'
                       : ''
                       }}</b>
                     post
                   </div>
                   <div *ngIf="row.is_post_r_reply != 1">
                     <b>{{ row.txt_name }}</b> replied to your reply on
                     <b>{{
                       row.int_thread_type_id == 1
                       ? 'mcq'
                       : row.int_thread_type_id == 2
                       ? 'sim'
                       : row.int_thread_type_id == 3
                       ? 'video'
                       : row.int_thread_type_id == 4
                       ? 'ebook'
                       : ''
                       }}</b>
                     post
                   </div>
                 </a>
               </div>
             </ng-container>
             <ng-container *ngIf="row.is_post_closed === 1">
               <div class="alert alert-success mb-0" role="alert">
                 <a
                    (click)="
                     marksNotificaionsread(row.int_post_id);
                     goToForum(
                       row.int_thread_type_id,
                       row.int_subject_id,
                       row.txt_question_id,
                       row.int_topic_id,
                       row.int_subtopic_id,
                       row.int_video_id
                     )
                   "
                    style="text-decoration: none !important"
                    *ngIf="row.is_post_closed === 1">
                   <div class="pr-2">
                     <div
                          class="font-weight-bold success-color-dark my-auto text-center px-2 text-white"
                          style="font-size: 20px">
                       R
                     </div>
                   </div>
                   <div class="my-auto" style="text-decoration: underline">
                     <b>{{ row.txt_name }}</b> marked your
                     <b>{{
                       row.int_thread_type_id == 1
                       ? 'mcq'
                       : row.int_thread_type_id == 2
                       ? 'sim'
                       : row.int_thread_type_id == 3
                       ? 'video'
                       : row.int_thread_type_id == 4
                       ? 'ebook'
                       : ''
                       }}</b>
                     post as resolved
                   </div>
                 </a>
               </div>
             </ng-container>
           </ng-container>
         </ng-container>
         <div class="text-center px-3 py-1" *ngIf="!notificationData.length">
           <div class="mx-auto mb-2" style="width: 100px">
             <img src="/assets/img/no_notifications.png" class="img-fluid" alt="No Notification Banner" />
           </div>
           <span class="d-block h6 small">No new notification</span>
         </div>
       </div>
          </li>
          <li *ngIf="userData.is_forum_admin != 1">
            <a mdbTooltip="FACS Payment" placement="top" [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/miles-facs-payment'" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_2"></span> -->
              <span class="top_header_icons">
                <img src="/assets/svg/FACS-Payment.svg" alt="Menu Icons">
              </span>
            </a>
          </li>

          <li *ngIf="userData.is_forum_admin != 1">
            <a mdbTooltip="UPS Courier" placement="top" [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/ups-courier'" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_7"></span> -->

              <span class="top_header_icons">
                <img src="/assets/svg/USP-Courier.svg" alt="Menu Icons">
              </span>
            </a>
          </li>
          <!-- userData.is_admin != 1&& -->

          <!-- <li *ngIf="userData.is_forum_admin != 1">
            <a mdbTooltip="Content Update" placement="top" (click)="contentUpdate(); showAttentionPopup()"
              routerLinkActive="active">
              <span class="top_header_icons icon_4"></span>
            </a>
          </li> -->
          <!-- <li *ngIf="((is_only_bridgecourse != 1 && is_alumni != 1) || (is_only_aicpa && is_alumni) != 1)  &&userData.is_forum_admin != 1">
            <a routerLinkActive="active" mdbTooltip="Search" placement="top" [routerLink]="
                    is_only_bridgecourse == 1 ||
                    is_only_aicpa == 1 ||
                    is_only_integrity == 1 ||
                    is_only_aicpa_inter == 1
                      ? '/lms/launchpad'
                      : '/lms/search'
                  " (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
              <span class="top_header_icons icon_9"></span>

            </a>
          </li> -->
          <!-- userData.is_admin != 1&& -->

          <!-- <li *ngIf="userData.is_forum_admin != 1">
            <a routerLinkActive="active" mdbTooltip="Tutorial" placement="top" [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/tutorials'" routerLinkActive="active">
              <span class="top_header_icons icon_6"></span>
            </a>
          </li> -->
        </ul>
      </div>

      <div class="right_menu_expand" [ngClass]="{'hidden': activeDiv !== 2}" [@fadeInOut] *ngIf="activeDiv === 2">
        <ul>
          <!-- <li>
            <a [routerLink]="
            is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
              ? '/lms/launchpad'
              : '/lms/liqui-loan'" routerLinkActive="active">
              <span class="top_header_icons icon_1"></span> Liquiloans
            </a>
          </li> -->

          <li>
            <!-- (click)="showbooksNotification()" -->
                        
            <a [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/book-delivery'" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_5"></span>  -->
              <span class="top_header_icons">
                <img src="/assets/svg/Order-Book.svg" alt="Menu Icon">
              </span> <br/>
              Order Book
            </a>
          </li>

          <li>
            <a [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/miles-facs-payment'" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_2"></span>  -->
              <span class="top_header_icons">
                <img src="/assets/svg/FACS-Payment.svg" alt="Menu Icon">
              </span> <br/>
              FACS Payment
            </a>
          </li>

          <li>
            <a [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/ups-courier'" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_7"></span> <br /> -->
              <span class="top_header_icons">
                <img src="/assets/svg/USP-Courier.svg" alt="Menu Icon">
              </span> <br/>
              UPS Courier
            </a>
          </li>

          <li>
            <a (click)="openContactModal(); showAttentionPopup()" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_8"></span> -->
              <span class="top_header_icons">
                <img src="/assets/svg/Contact.svg" alt="Menu Icon">
              </span> <br/>
              Contact
            </a>
          </li>

          <li *ngIf="(is_only_bridgecourse != 1 && is_alumni != 1) || (is_only_aicpa && is_alumni) != 1">
            <a style="font-weight: 400;" [routerLink]="
                    is_only_bridgecourse == 1 ||
                    is_only_aicpa == 1 ||
                    is_only_integrity == 1 ||
                    is_only_aicpa_inter == 1
                      ? '/lms/launchpad'
                      : '/lms/search'
                  " (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_9"></span>  -->
              <span class="top_header_icons">
                <img src="/assets/svg/search.svg" alt="Menu Icon">
              </span> <br/>
              Search

            </a>
          </li>

          <li
                        class="nav-item dropdown"
                        dropdown
                        *ngIf="(is_only_bridgecourse || is_only_aicpa || is_only_integrity) != 1">
                        <!-- <a dropdownToggle type="button" class="nav-link waves-light notify_link" mdbWavesEffect>
                          <i class="fas fa-bell icon_11" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i>
                          <span class="icon_11">Notification</span>
                          <mdb-badge
                            pill="danger"
                            danger="true"
                            *ngIf="notificationData.length">{{ notificationData.length }}</mdb-badge>
                        </a> -->

                        <a class="nav-link waves-light notify_link" (click)="notifiCation(); showAttentionPopup()" routerLinkActive="active">
                          <!-- <i class="fas fa-bell icon_11" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i> -->
                          <span class="top_header_icons">
                            <img src="/assets/svg/Notification.svg" alt="Menu Icon">
                          </span> <br/>
                          <span class="icon_11">Notification</span>
                        </a>
                      <div
                           class="dropdown-menu dropdown-primary dropdown-menu-right p-0 notification-block"
                           role="menu"
                           style="border: 2px solid #c3c6ca; min-width: 20rem">
                        <div
                             *ngIf="notificationData.length"
                             class="notification-header font-weight-bold d-flex justify-content-between">
                          <span class="my-auto">NEW</span>
                          <button
                                  size="sm"
                                  color="link"
                                  mdbBtn
                                  mdbWavesEffects
                                  class="m-0 font-weight-bold"
                                  (click)="marksNotificaionsread(0)"
                                  style="font-size: 11px; padding: 0.2rem 0.5rem; color: #2d6fdc !important">
                            Clear all
                          </button>
                        </div>
                        <ng-container *ngIf="notificationData.length">
                          <ng-container *ngFor="let row of notificationData">
                            <ng-container *ngIf="row.is_post_closed === 0">
                              <div class="alert alert-info mb-0" role="alert" *ngIf="row.is_forum_admin === 0">
                                <a
                                   (click)="
                                    marksNotificaionsread(row.int_post_id);
                                    goToForum(
                                      row.int_thread_type_id,
                                      row.int_subject_id,
                                      row.txt_question_id,
                                      row.int_topic_id,
                                      row.int_subtopic_id,
                                      row.int_video_id
                                    )
                                  ">
                                  <div class="pr-2">
                                    <i
                                       class="fad"
                                       [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-alt-lines' : 'fa-comments-alt'"
                                       style="font-size: 1.2rem"></i>
                                  </div>
                                  <div *ngIf="row.is_post_r_reply === 1">
                                    <b>{{ row.txt_name }}</b> replied to your
                                    <b>{{
                                      row.int_thread_type_id == 1
                                      ? 'mcq'
                                      : row.int_thread_type_id == 2
                                      ? 'sim'
                                      : row.int_thread_type_id == 3
                                      ? 'video'
                                      : row.int_thread_type_id == 4
                                      ? 'ebook'
                                      : ''
                                      }}</b>
                                    post
                                  </div>
                                  <div *ngIf="row.is_post_r_reply != 1">
                                    <b>{{ row.txt_name }}</b> replied to your reply on
                                    <b>{{
                                      row.int_thread_type_id == 1
                                      ? 'mcq'
                                      : row.int_thread_type_id == 2
                                      ? 'sim'
                                      : row.int_thread_type_id == 3
                                      ? 'video'
                                      : row.int_thread_type_id == 4
                                      ? 'ebook'
                                      : ''
                                      }}</b>
                                    post
                                  </div>
                                </a>
                              </div>
                              <div class="alert alert-primary mb-0" role="alert" *ngIf="row.is_forum_admin === 1">
                                <a
                                   (click)="
                                    marksNotificaionsread(row.int_post_id);
                                    goToForum(
                                      row.int_thread_type_id,
                                      row.int_subject_id,
                                      row.txt_question_id,
                                      row.int_topic_id,
                                      row.int_subtopic_id,
                                      row.int_video_id
                                    )
                                  ">
                                  <div class="pr-2">
                                    <i
                                       class="fad"
                                       [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-lines' : 'fa-comments'"
                                       style="font-size: 1.2rem"></i>
                                  </div>
                                  <div *ngIf="row.is_post_r_reply === 1">
                                    <b>{{ row.txt_name }}</b> replied to your
                                    <b>{{
                                      row.int_thread_type_id == 1
                                      ? 'mcq'
                                      : row.int_thread_type_id == 2
                                      ? 'sim'
                                      : row.int_thread_type_id == 3
                                      ? 'video'
                                      : row.int_thread_type_id == 4
                                      ? 'ebook'
                                      : ''
                                      }}</b>
                                    post
                                  </div>
                                  <div *ngIf="row.is_post_r_reply != 1">
                                    <b>{{ row.txt_name }}</b> replied to your reply on
                                    <b>{{
                                      row.int_thread_type_id == 1
                                      ? 'mcq'
                                      : row.int_thread_type_id == 2
                                      ? 'sim'
                                      : row.int_thread_type_id == 3
                                      ? 'video'
                                      : row.int_thread_type_id == 4
                                      ? 'ebook'
                                      : ''
                                      }}</b>
                                    post
                                  </div>
                                </a>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="row.is_post_closed === 1">
                              <div class="alert alert-success mb-0" role="alert">
                                <a
                                   (click)="
                                    marksNotificaionsread(row.int_post_id);
                                    goToForum(
                                      row.int_thread_type_id,
                                      row.int_subject_id,
                                      row.txt_question_id,
                                      row.int_topic_id,
                                      row.int_subtopic_id,
                                      row.int_video_id
                                    )
                                  "
                                   style="text-decoration: none !important"
                                   *ngIf="row.is_post_closed === 1">
                                  <div class="pr-2">
                                    <div
                                         class="font-weight-bold success-color-dark my-auto text-center px-2 text-white"
                                         style="font-size: 20px">
                                      R
                                    </div>
                                  </div>
                                  <div class="my-auto" style="text-decoration: underline">
                                    <b>{{ row.txt_name }}</b> marked your
                                    <b>{{
                                      row.int_thread_type_id == 1
                                      ? 'mcq'
                                      : row.int_thread_type_id == 2
                                      ? 'sim'
                                      : row.int_thread_type_id == 3
                                      ? 'video'
                                      : row.int_thread_type_id == 4
                                      ? 'ebook'
                                      : ''
                                      }}</b>
                                    post as resolved
                                  </div>
                                </a>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <div class="text-center px-3 py-1" *ngIf="!notificationData.length">
                          <div class="mx-auto mb-2" style="width: 100px">
                            <img src="/assets/img/no_notifications.png" class="img-fluid" alt="No Notification Banner" />
                          </div>
                          <span class="d-block h6 small">No new notification</span>
                        </div>
                      </div>
          </li>

          <li *ngIf="userData.is_forum_admin != 1">
            <a [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/mileage/attendance'" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_3"></span> -->
              <span class="top_header_icons">
                <img src="/assets/svg/Attendance.svg" alt="Menu Icon">
              </span> <br/>
               Attendance
            </a>
          </li>
          <li>
            <a (click)="contentUpdate(); showAttentionPopup()" routerLinkActive="active">
              <!-- <span class="top_header_icons icon_3"></span> -->
              <span class="top_header_icons">
                <img src="/assets/svg/Information-icon.svg" alt="Menu Icon">
              </span> <br/>
              Content Updates
            </a>
          </li>

          <li>
            <a (click)="reportDownload($event)" [disabled]="isLoading">
              <span class="top_header_icons">
                <img src="/assets/masterclass/download.svg" alt="Menu Icon" *ngIf="!isLoading">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </span> 
              <br/>
              <span *ngIf="!isLoading">Progress Report</span>
              <span *ngIf="isLoading">Loading...</span>
            </a>
          </li>
          

          <!-- <li>
            <a [routerLink]="
            is_only_bridgecourse == 1 ||
            is_only_aicpa == 1 ||
            is_only_integrity == 1 ||
            is_only_aicpa_inter == 1
              ? '/lms/launchpad'
              : '/lms/tutorials'" routerLinkActive="active">
              <span class="top_header_icons icon_6"></span> Tutorial
            </a>
          </li>       --> 

          <!--notification -->
          
          
          
          
          
                      

          

          <!-- <li>
            <a (click)="contentUpdate(); showAttentionPopup()" routerLinkActive="active">
              <span class="top_header_icons icon_4"></span> Content Update
            </a>
          </li> -->

          

          

         
          <!-- <li>
            <a (click)="cpaEvolution(); showAttentionPopup()" routerLinkActive="active">
              <span class="top_header_icons icon_10"></span> <br>CPA Evolution
            </a>
          </li> -->

          <!-- <li>
            <a (click)="openContactModal(); showAttentionPopup()" routerLinkActive="active">
              <span class="top_header_icons icon_9"></span> Search
            </a>
          </li> -->

          
        </ul>
      </div>
    </div>
    <button class="show_more_btn" (click)="toggleDiv()">{{ buttonText }}</button>
  </div>
  <div class="px_spc">
    <!-- <div *ngIf="menuShowNow"
      class="select_subject shadow-sm d-flex justify-content-between align-items-center {{showBackDrop && 'show-backdrop'}}"
      [style.background-color]="dropdownBackgroundColor" (click)="showBackDrop=!showBackDrop"
      (blur)="showBackDrop=false" (mouseleave)="showBackDrop=false">
      <span>{{subjectName.subject_name}}</span>
      <span><i class="fa fa-refresh" aria-hidden="true"></i></span>
      <div class="nav-menu-options">
        <ul>
          <li [ngClass]="{
            'dis-option' : selectedOption===option.subject_id_encrypt
          }" (click)="selectedOption=option.subject_id_encrypt;navgigateToLaunchpad()" *ngFor=" let option of options">
            {{ option.subject_name}}</li>
        </ul>
      </div>
    </div> -->
    <div class="sidebar-links">
      <ul *ngIf="userData?.is_only_iimi != 1">
        <li>
          <a routerLink="/lms/launchpad" routerLinkActive="active">
            <div class="icon">
              <span class="sidebar_icons launchpad"></span>
            </div>
            <span class="link hide">Launchpad</span>
          </a>
        </li>
        <!-- <li class="slider-mega-menu">
          <mdb-accordion [multiple]="false">
            <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head>
                  <div class="mega-menu-head">
                    <span><img src="../../assets/svg/core-menu-icon.svg" alt="Menu"></span>
                    <span class="mega-vertical-line"><img src="../../assets/svg/vertical-line.svg" alt="Menu"></span>
                    <span class="mega-menu-title">Core Subjects</span>
                  </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div class="submenu-list">
                    <ul>
                      <li *ngIf="is_alumni != 1 && subjectDetails[0]?.is_mcqs_purchased === 2">
                        <a routerLinkActive="active"
                          [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad' : 
                        subjectDetails[0]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[0]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[0]?.subject_id_encrypt"
                          style="background: #457B9D; ">
                          <div class="icon">
                            <span class="sidebar_icons financial"></span>
                          </div>
                          <span class="link hide text-white">Financial</span>
                        </a>
                      </li>
                      <li *ngIf="is_alumni != 1 && subjectDetails[1]?.is_mcqs_purchased === 2">
                        <a routerLinkActive="active"
                          [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad': subjectDetails[1]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[1]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[1]?.subject_id_encrypt"
                          style="background: #84A59D; ">
                          <div class="icon">
                            <span class="sidebar_icons auditing"></span>
                          </div>
                          <span class="link hide text-white">Auditing</span>
                        </a>
                      </li>
                      <li *ngIf="is_alumni != 1 && subjectDetails[3]?.is_mcqs_purchased === 2">
                        <a routerLinkActive="active"
                          [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': subjectDetails[3]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[3]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[3]?.subject_id_encrypt"
                          style="background: #E63946; ">
                          <div class="icon">
                            <span class="sidebar_icons regulation"></span>
                          </div>
                          <span class="link hide text-white">Regulation</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
            <mdb-accordion-item>
              <mdb-accordion-item-head>
                <div class="mega-menu-head">
                  <span><img src="../../assets/svg/descipline-menu-icon.svg" alt="Menu"></span>
                  <span class="mega-vertical-line"><img src="../../assets/svg/vertical-line.svg" alt="Menu"></span>
                  <span class="mega-menu-title">Discipline Subjects</span>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="submenu-list">
                  <ul>>
                    <li *ngIf="is_alumni != 1 && subjectDetails[4]?.is_mcqs_purchased === 2">
                      <a routerLinkActive="active"
                        [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': subjectDetails[4]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[4]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[4]?.subject_id_encrypt"
                        style="background: #F39F5A; ">
                        <div class="icon business_icon">
                          <i class="fa fa-bar-chart" aria-hidden="true"></i>
                        </div>
                        <span class="link hide text-white">Business(BAR)</span>
                      </a>
                    </li>
                    <li *ngIf="is_alumni != 1 && subjectDetails[5]?.is_mcqs_purchased === 2">
                      <a (click)="comingSoon();"
                        style="background: #CB6745; ">
                        <div class="icon">
                          <span class="tax-icon">
                            <img src="../../assets/svg/tax-icon.svg" alt="Menu">
                          </span>
                        </div>
                        <span class="link hide text-white">Tax Compliance</span>
                      </a>
                    </li>

                    <li *ngIf="is_alumni != 1 && subjectDetails[6]?.is_mcqs_purchased === 2">
                      <a (click)="comingSoon();"
                        style="background: #BDB17F; ">
                        <div class="icon">
                          <span class="information-icon">
                            <img src="../../assets/svg/Information-icon.svg" alt="Menu">
                          </span>
                        </div>
                        <span class="link hide text-white">Information System</span>
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </li> -->
        <li *ngIf="is_alumni != 1 && subjectDetails[0]?.is_mcqs_purchased === 2">
          <a routerLinkActive="active"
            [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad' : 
          subjectDetails[0]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[0]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[0]?.subject_id_encrypt"
            style="background: #457B9D; ">
            <div class="icon">
              <span class="sidebar_icons financial"></span>
            </div>
            <span class="link hide text-white">Financial</span>
          </a>
        </li>
        <li *ngIf="is_alumni != 1 && subjectDetails[1]?.is_mcqs_purchased === 2">
          <a routerLinkActive="active"
            [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad': subjectDetails[1]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[1]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[1]?.subject_id_encrypt"
            style="background: #84A59D; ">
            <div class="icon">
              <span class="sidebar_icons auditing"></span>
            </div>
            <span class="link hide text-white">Auditing</span>
          </a>
        </li>
        <!-- <li *ngIf="is_alumni != 1 && subjectDetails[2]?.is_mcqs_purchased === 2">
          <a routerLinkActive="active"
            [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad' : subjectDetails[2]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[2]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[2]?.subject_id_encrypt"
            style="background: #F6BD60; ">
            <div class="icon business_icon">
              <span class="sidebar_icons business"></span>
            </div>
            <span class="link hide text-white">Business</span>
          </a>
        </li> -->
        <li *ngIf="is_alumni != 1 && subjectDetails[3]?.is_mcqs_purchased === 2">
          <a routerLinkActive="active"
            [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': subjectDetails[3]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[3]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[3]?.subject_id_encrypt"
            style="background: #E63946; ">
            <div class="icon">
              <span class="sidebar_icons regulation"></span>
            </div>
            <span class="link hide text-white">Regulation</span>
          </a>
        </li>
        <li *ngIf="((is_alumni != 1 && subjectDetails[4]?.is_mcqs_purchased === 2 && subjectDetails[4]?.is_selected_discipline === 1) || is_forum_admin === 1 || is_discipline_selected === 0)">
          <a routerLinkActive="active"
            [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': subjectDetails[4]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[4]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[4]?.subject_id_encrypt"
            style="background: #F39F5A; ">
            <div class="icon business_icon">
              <i class="fa fa-bar-chart" aria-hidden="true"></i>
            </div>
            <span class="link hide text-white">Business</span>
          </a>
        </li>
        <li *ngIf="((is_alumni != 1 && subjectDetails[5]?.is_mcqs_purchased === 2 && subjectDetails[5]?.is_selected_discipline === 1) || is_forum_admin === 1 || is_discipline_selected === 0)">
            <a routerLinkActive="active"
              [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': subjectDetails[5]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[5]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[5]?.subject_id_encrypt"
              style="background: #CB6745; ">
              <span class="tax-icon">
                <img src="../../assets/svg/tax-icon.svg" alt="Menu">
              </span>
              <span class="link hide text-white">Tax Compliance</span>
            </a>
          </li>
          <li *ngIf="((is_alumni != 1 && subjectDetails[6]?.is_mcqs_purchased === 2 && subjectDetails[6]?.is_selected_discipline === 1) || is_forum_admin === 1)">
            <a routerLinkActive="active"
              [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': subjectDetails[6]?.reference_year===2 ? '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[6]?.subject_id_encrypt : '/lms/subject/subjectname/topic' + '/' + subjectDetails[6]?.subject_id_encrypt"
              style="background: #BDB17F; ">
              <span class="information-icon">
                <img src="../../assets/svg/Information-icon.svg" alt="Menu">
              </span>
              <span class="link hide text-white">Information Systems</span>
            </a>
          </li>
        <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/us-pathway'" (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons us_pathway_icon"></span>
            </div>
            <span class="link hide">Miles US Pathway </span>
          </a>
        </li>

        <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/jobs/helpdesk'" (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons masterclass_icon"></span>
            </div>
            <span class="link hide">Helpdesk</span>
          </a>
        </li>

        <!-- <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/masterclass'" (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup(); getToken()">
            <div class="icon">
              <span class="sidebar_icons masterclass_icon"></span>
            </div>
            <span class="link hide">Masterclass </span>
          </a>
        </li> -->

        <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== '' && userData?.is_iimi_jagsom == 1">
          <a  [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/university-programme'" routerLinkActive="active"  (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons iimi"></span>
            </div>
            <span class="link hide">IIMI-PGCIAA</span>
          </a>
        </li>
        <li>
          <a  [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/mentoring/'" routerLinkActive="active"  (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons mentoring"></span>
            </div>
            <span class="link hide">Book Mentoring</span>
          </a>
        </li>


        <li>
          <a [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/forum'" routerLinkActive="active" (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons aud_forum"></span>
            </div>
            <span class="link hide">Forum</span>
          </a>
        </li>

       

        <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/mileage/webinar/subject'"
            (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons webinar"></span>
            </div>
            <span class="link hide">Webinar</span>
          </a>
        </li>

        <li >
          <a (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"  [routerLink]="
          is_only_aicpa_inter
            ? 'lms/cpa-inter'
            : is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
            ? '/lms/launchpad'
            : '/lms/classroom'" routerLinkActive="active">
            <div class="icon">
              <span class="sidebar_icons face_to_face"></span>
            </div>
            <span class="link hide">Face to Face</span>
          </a>
        </li>

        <li>
          <a (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"  
            [routerLink]="
           is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
             ? '/lms/launchpad'
             : '/lms/study-lounge/new'"  routerLinkActive="active" >
            <div class="icon">
              <span class="sidebar_icons study_longue"></span>
            </div>
            <span class="link hide">Study Lounge</span>
          </a>
        </li>


        <!-- <li>
          <a (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/practice-exam'" routerLinkActive="active">
            <div class="icon">
              <span class="sidebar_icons practice_exam"></span>
            </div>
            <span class="link hide">Practice Exam</span>
          </a>
        </li> -->
        <li>
          <a (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"  [routerLink]="
              is_only_aicpa_inter
                ? 'lms/cpa-inter'
                : is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
                ? '/lms/launchpad'
                : '/lms/fab'
            " routerLinkActive="active">
            <div class="icon">
              <span class="sidebar_icons accounting_101"></span>
            </div>
            <span class="link hide">Accounting.101</span>
          </a>
        </li>
        <li>
          <a (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"  [routerLink]="
              is_only_aicpa_inter
                ? 'lms/cpa-inter'
                : is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
                ? '/lms/launchpad'
                : '/lms/bootcamp'
            " routerLinkActive="active">
            <div class="icon">
              <span class="sidebar_icons bootcamps_icon"></span>
            </div>
            <span class="link hide">Bootcamps</span>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/mileage/gameplan'" (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <div class="icon">
              <span class="sidebar_icons gameplan_tool"></span>
            </div>
            <span class="link hide">Gameplan Tool </span>
          </a>
        </li>

        

        <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/mileage/study_planner'" 
            (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
            <div class="icon">
              <span class="sidebar_icons study_planner"></span>
            </div>
            <span class="link hide">Study Planner</span>
          </a>
        </li>

        <!-- <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_bridgecourse == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/cpa-inter'" (click)="showNAPopup(true); showNAPOPUpCpaInter()">
            <div class="icon">
              <span class="sidebar_icons cpa_inter"></span>
            </div>
            <span class="link hide">CPA-Inter</span>
          </a>
        </li> -->

        <li>
          <a routerLinkActive="active" [routerLink]="
          is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/bridge-course'" (click)="showNAPopUp(); closePopover(0)">
            <div class="icon">
              <span class="sidebar_icons jain_university"></span>
            </div>
            <!-- <span class="link hide">Jain University</span> -->
            <span class="link hide">Bridge Course</span>
          </a>
        </li>

        <!-- <li>
          <a [routerLink]="'/lms/bridge-course/jagsom'" (click)="showNAPopUp(); closePopover(0)">
            <div class="icon">
              <span class="sidebar_icons jagsom_university"></span>
            </div>
            <span class="link hide">JAGSOM Bridge Course</span>
          </a>
        </li> -->
        
        <li  >
          <a (click)="
          closePopover(0); showNAPopup(); closePopover(0); showNAPopUp(); !is_only_aicpa_inter && checkurl()">
            <div class="icon">
              <span class="sidebar_icons maac"></span>
            </div>
            <span class="link hide">Referral</span>
          </a>
        </li>

      </ul>
      <ul *ngIf="userData?.is_only_iimi == 1">
        <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== '' && userData?.is_iimi_jagsom == 1">
          <a routerLink="/lms/university-programme" routerLinkActive="active">
            <div class="icon">
              <span class="sidebar_icons iimi"></span>
            </div>
            <span class="link hide">IIMI-PGCIAA</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="sidebar-footer" mdbDropdown [dropup]="true">
    <div class="exam_status">
      <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/dots_icon.svg" class="dots_icon"
        mdbDropdownToggle>
      <img
        [src]="userData.profile_img?userData.profile_img:'https://storage.googleapis.com/miles-next_website-lms-gptool/LMS_2024_newImages/dummy_user_icon.png'"
        class="img-fluid profile_pic" [ngClass]="userData.profile_img?'':'bg-white'"
        [ngStyle]="{'padding': userData.profile_img ? '' : '0.1rem'}">
      <div class="align-self-center">
        <p class="name">{{userData.stud_name}}</p>
      </div>
      <div class="dropdown-menu footer_menu_dropdown">
        <ul>
          <li style="overflow-wrap: break-word;">
            <div mdbTooltip="{{userData.email}}" placement="top" style="white-space: nowrap;width: 95%;overflow: hidden;text-overflow: ellipsis;">
              <i class="fa fa-user mr-2" aria-hidden="true"></i>
              {{userData.email}}  
            </div>
          </li>
          <hr style="margin: 0px;background: #a6a6a6;">
          <li><a routerLink="/lms/my-profile">My Profile</a></li>
          <!-- <li><a routerLink="/lms/jobs/helpdesk">Helpdesk & Feedback</a></li> -->
          <li><a routerLink="/lms/change-password">Change Password</a></li>
          <li><a (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
    <!-- <div>
      <p class="email">{{userData.email}}</p>
    </div> -->
  </div>
</nav>

<section class="mobile_header">

  <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/logo.png" class="logo" alt="">
  <label for="check">
    <input type="checkbox" id="check">
    <span (click)="showMobileSideBar=!showMobileSideBar"><img
        src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/Burger_menu.svg" id="btn"></span>

    <span (click)="showMobileSideBar=!showMobileSideBar"><img
        src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/close_icon.svg" id="cancel"
        [ngStyle]="{'left' : showMobileSideBar ? '220px' : '-195px' }"></span>
  </label>
  <div class="icons_box">
    <div class="right_menu" [ngClass]="{'hidden': activeDiv !== 1}" [@fadeInOut] *ngIf="activeDiv === 1">
      <ul>
        <!-- <li>
          <a [routerLink]="
          is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
            ? '/lms/launchpad'
            : '/lms/liqui-loan'" routerLinkActive="active">
            <span class="top_header_icons icon_1"></span>
          </a>
        </li> -->

        <li>
          <a [routerLink]="
          is_only_bridgecourse == 1 ||
          is_only_aicpa == 1 ||
          is_only_integrity == 1 ||
          is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/mileage/attendance'" routerLinkActive="active">
            <span class="top_header_icons icon_3"></span>
          </a>
        </li>

        <li>
          <a [routerLink]="
          is_only_bridgecourse == 1 ||
          is_only_aicpa == 1 ||
          is_only_integrity == 1 ||
          is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/tutorials'" routerLinkActive="active">
            <span class="top_header_icons icon_6"></span>
          </a>
        </li>

        <li>
          <a [routerLink]="
          is_only_bridgecourse == 1 ||
          is_only_aicpa == 1 ||
          is_only_integrity == 1 ||
          is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/miles-facs-payment'" routerLinkActive="active">
            <span class="top_header_icons icon_2"></span>
          </a>
        </li>

        <li>
          <a (click)="contentUpdate(); showAttentionPopup()" routerLinkActive="active">
            <span class="top_header_icons icon_4"></span>
          </a>
        </li>

        <li>
          <!-- [routerLink]="
                    is_only_bridgecourse == 1 ||
                    is_only_aicpa == 1 ||
                    is_only_integrity == 1 ||
                    is_only_aicpa_inter == 1
                      ? '/lms/launchpad'
                      : '/lms/book-delivery'" routerLinkActive="active" -->
          <a (click)="showbooksNotification()">
            <span class="top_header_icons icon_5"></span>
          </a>
        </li>

        <li>
          <a [routerLink]="
          is_only_bridgecourse == 1 ||
          is_only_aicpa == 1 ||
          is_only_integrity == 1 ||
          is_only_aicpa_inter == 1
            ? '/lms/launchpad'
            : '/lms/ups-courier'" routerLinkActive="active">
            <span class="top_header_icons icon_7"></span> <br />
          </a>
        </li>

        <li>
          <a (click)="openContactModal(); showAttentionPopup()" routerLinkActive="active">
            <span class="top_header_icons icon_8"></span>
          </a>
        </li>
        <li>
          <a (click)="cpaEvolution(); showAttentionPopup()" routerLinkActive="active">
            <span class="top_header_icons icon_10"></span>
          </a>
        </li>

        <li *ngIf="(is_only_bridgecourse != 1 && is_alumni != 1) || (is_only_aicpa && is_alumni) != 1">
          <a routerLinkActive="active" [routerLink]="
                  is_only_bridgecourse == 1 ||
                  is_only_aicpa == 1 ||
                  is_only_integrity == 1 ||
                  is_only_aicpa_inter == 1
                    ? '/lms/launchpad'
                    : '/lms/search'
                " (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
            <span class="top_header_icons icon_9"></span>

          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="sidebar" [ngStyle]="{'left' : showMobileSideBar ? '0px' : '-780px' }">
    <nav>
      <div class="sidebar-top">
        <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/logo.png" class="logo" alt="">
      </div>
      <div class="px_spc">
        <div class="sidebar-links">
          <ul *ngIf="userData?.is_only_iimi != 1">
            <li>
              <a (click)="closeMobileBtn()" routerLink="/lms/launchpad" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons launchpad"></span>
                </div>
                <span class="link hide">Launchpad</span>
              </a>
            </li>
            <li *ngIf="is_alumni != 1 && subjectDetails[0]?.is_mcqs_purchased === 2">
              <a (click)="closeMobileBtn()" routerLinkActive="active"
                [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad' : '/lms/v2/subject/subjectname/topic/' + subjectDetails[0]?.subject_id_encrypt"
                style="background: #457B9D; color:#fff">
                <div class="icon">
                  <span class="sidebar_icons financial"></span>
                </div>
                <span class="link hide" style="color:#fff;">Financial</span>
              </a>
            </li>
            <li *ngIf="is_alumni != 1 && subjectDetails[1]?.is_mcqs_purchased === 2">
              <a (click)="closeMobileBtn()" routerLinkActive="active"
                [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad': '/lms/v2/subject/subjectname/topic' + '/' + subjectDetails[1]?.subject_id_encrypt"
                style="background: #84A59D;">
                <div class="icon">
                  <span class="sidebar_icons auditing"></span>
                </div>
                <span class="link hide" style="color:#fff;">Auditing</span>
              </a>
            </li>
            <li *ngIf="is_alumni != 1 && subjectDetails[2]?.is_mcqs_purchased === 2">
              <a (click)="closeMobileBtn()" routerLinkActive="active"
                [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1 ? '/lms/launchpad' : '/lms/v2/subject/subjectname/topic/' + subjectDetails[4]?.subject_id_encrypt"
                style="background: #edbf6f;">
                <div class="icon">
                  <span class="sidebar_icons business"></span>
                </div>
                <span class="link hide" style="color:#fff;">Business</span>
              </a>
            </li>
            <li *ngIf="is_alumni != 1 && subjectDetails[3]?.is_mcqs_purchased === 2">
              <a (click)="closeMobileBtn()" routerLinkActive="active"
                [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1? '/lms/launchpad': '/lms/v2/subject/subjectname/topic/' + subjectDetails[3]?.subject_id_encrypt"
                style="background: #d4494c; color:#fff">
                <div class="icon">
                  <span class="sidebar_icons regulation"></span>
                </div>
                <span class="link hide" style="color:#fff;">Regulation</span>
              </a>
            </li>
            <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== '' && userData?.is_iimi_jagsom == 1">
              <a (click)="closeMobileBtn()" routerLink="/lms/university-programme" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons iimi"></span>
                </div>
                <span class="link hide">IIMI-PGCIAA</span>
              </a>
            </li>
            <li>
              <a (click)="closeMobileBtn()" routerLink="/lms/mentoring/home" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons mentoring"></span>
                </div>
                <span class="link hide">Book Mentoring</span>
              </a>
            </li>

            <li>
              <a (click)="closeMobileBtn()" routerLink="/lms/study-lounge/new" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons mentoring"></span>
                </div>
                <span class="link hide">Study Lounge</span>
              </a>
            </li>


            <li>
              <a (click)="closeMobileBtn()" [routerLink]="
              is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/forum'" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons aud_forum"></span>
                </div>
                <span class="link hide">Forum</span>
              </a>
            </li>

            <li>
              <a routerLinkActive="active" [routerLink]="
              is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/mileage/webinar/subject'"
                (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup();closeMobileBtn()">
                <div class="icon">
                  <span class="sidebar_icons webinar"></span>
                </div>
                <span class="link hide">Webinar</span>
              </a>
            </li>

            

            <li>
              <a (click)="closeMobileBtn()" [routerLink]="
              is_only_aicpa_inter
                ? 'lms/cpa-inter'
                : is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
                ? '/lms/launchpad'
                : '/lms/classroom'" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons face_to_face"></span>
                </div>
                <span class="link hide">Face to Face</span>
              </a>
            </li>

            <li>
              <a (click)="closeMobileBtn()" routerLink="/lms/study-lounge/new" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons study_longue"></span>
                </div>
                <span class="link hide">Study Longue</span>
              </a>
            </li>

            <!-- <li>
              <a (click)="closeMobileBtn()" [routerLink]="
              is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/practice-exam'" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons practice_exam"></span>
                </div>
                <span class="link hide">Practice Exam</span>
              </a>
            </li> -->
            <li>
              <a (click)="closeMobileBtn()" [routerLink]="
                  is_only_aicpa_inter
                    ? 'lms/cpa-inter'
                    : is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
                    ? '/lms/launchpad'
                    : '/lms/fab'
                " routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons accounting_101"></span>
                </div>
                <span class="link hide">Accounting.101</span>
              </a>
            </li>
            <li>
              <a (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"  [routerLink]="
                  is_only_aicpa_inter
                    ? 'lms/cpa-inter'
                    : is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
                    ? '/lms/launchpad'
                    : '/lms/bootcamp'
                " routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons bootcamps_icon"></span>
                </div>
                <span class="link hide">Bootcamps</span>
              </a>
            </li>
            <li>
              <a routerLinkActive="active" [routerLink]="
              is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/mileage/gameplan'"
                (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup();closeMobileBtn()">
                <div class="icon">
                  <span class="sidebar_icons gameplan_tool"></span>
                </div>
                <span class="link hide">Gameplan Tool</span>
              </a>
            </li>

            <li>
              <a routerLinkActive="active" [routerLink]="
              is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/mileage/study_planner'"
                (click)="closePopover(0); showAttentionPopup(); showNAPopup();closeMobileBtn()">
                <div class="icon">
                  <span class="sidebar_icons study_planner"></span>
                </div>
                <span class="link hide">Study Planner</span>
              </a>
            </li>

            <li>
              <a routerLinkActive="active" 
              [routerLink]="
              is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/cpa-inter'"
                (click)="showNAPopup(true); showNAPOPUpCpaInter();closeMobileBtn()">
                <div class="icon">
                  <span class="sidebar_icons cpa_inter"></span>
                </div>
                <span class="link hide">CPA-Inter</span>
              </a>
            </li>

            <li>
              <a routerLinkActive="active" [routerLink]="is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
                ? '/lms/launchpad'
                : '/lms/bridge-course'" (click)="showNAPopUp(); closePopover(0);closeMobileBtn()">
                <div class="icon">
                  <span class="sidebar_icons jain_university"></span>
                </div>
                <span class="link hide">Jain University</span>
              </a>
            </li>

            <li *ngIf="is_only_bridgecourse !== 1 && is_only_aicpa == 1 && is_only_integrity == 1 && is_only_aicpa_inter == 1">
              <a
                (click)="
              closeMobileBtn();closePopover(0); showNAPopup(); closePopover(0); showNAPopUp(); !is_only_aicpa_inter && checkurl()">
                <div class="icon">
                  <span class="sidebar_icons maac"></span>
                </div>
                <span class="link hide">M.A.C</span>
              </a>
            </li>

          </ul>
          <ul *ngIf="userData?.is_only_iimi == 1 && userData?.is_iimi_jagsom == 1">
            <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== ''">
              <a (click)="closeMobileBtn()" routerLink="/lms/university-programme" routerLinkActive="active">
                <div class="icon">
                  <span class="sidebar_icons iimi"></span>
                </div>
                <span class="link hide">IIMI-PGCIAA</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar-footer" mdbDropdown [dropup]="true">
        <div class="exam_status">
          <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/LMS-2024/Header/dots_icon.svg"
            class="dots_icon" mdbDropdownToggle>
          <img
            [src]="userData.profile_img?userData.profile_img:'https://storage.googleapis.com/miles-next_website-lms-gptool/LMS_2024_newImages/dummy_user_icon.png'"
            class="img-fluid profile_pic" [ngClass]="userData.profile_img?'':'bg-white'"
            [ngStyle]="{'padding': userData.profile_img ? '' : '0.1rem'}">
          <div>
            <p class="name">{{userData.stud_name}}</p>
            <p class="email">{{userData.email}}</p>
          </div>
          <div class="dropdown-menu footer_menu_dropdown">
            <ul>
              <li><a routerLink="/lms/my-profile">Profile</a></li>
              <!-- <li><a routerLink="/lms/jobs/helpdesk">Helpdesk & Feedback</a></li> -->
              <li><a routerLink="/lms/change-password">Change Password</a></li>
              <li><a (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</section>

<div style="position: relative;padding-bottom:30px;">
  <router-outlet></router-outlet>
</div>
