import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LmsFeedbackService {
  apiUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  getCategoryDropdown(params) {
    return this.http.post(this.apiUrl + "feedback/dropdownCategoryList", params);
  }

  getCategoryQuestionList(params) {
    return this.http.post(this.apiUrl + "feedback/getCategoryQuestionList", params);
  }

  getTrainerDropdown(params) {
    return this.http.post(this.apiUrl + "feedback/dropdownTrainerList", params);
  }

  updateStudentFeedback(params) {
    return this.http.post(this.apiUrl + "feedback/updateStudentFeedback", params);
  }

  getSubquestionOnRating(params) {
    return this.http.post(this.apiUrl + "feedback/getSubquestionOnRating", params);
  }

  getTicketList(params) {
    return this.http.post(this.apiUrl + "feedback/getStudentHelpdeskList", params);
  }
  
  getHelpdeskDetails(data){
    return this.http.post(this.apiUrl + "helpdesk/getHelpdeskDetails", data);
  }

  updateHelpdeskData(data){
    return this.http.post(this.apiUrl + "feedback/updateHelpdeskData", data);
  }

  updateStudentReopenStatus(data){
    return this.http.post(this.apiUrl + "helpdesk/updateStudentReopenStatus", data);
  }

  dropdownServiceTeamList(data){
    return this.http.post(this.apiUrl + "feedback/dropdownServiceTeamList", data);
  }
  
  editStudentHelpdeskData(data){
    return this.http.post(this.apiUrl + "helpdesk/editStudentHelpdeskData", data);
  }

  escalateStudentHelpdeskData(data){
    return this.http.post(this.apiUrl + "helpdesk/escalateStudentHelpdeskData", data);
  }

  getGPTeamDropdown(params) {
    return this.http.post(this.apiUrl + "helpdesk/getGPTeamDropdownList", params);
  }

  getStudentPendingHelpdeskFeedbackList(params) {
    return this.http.post(this.apiUrl + "feedback/getStudentPendingHelpdeskFeedbackList", params);
  }

  getStudentPendingFaceToFaceFeedbackList(params) {
    return this.http.post(this.apiUrl + "getStudentPendingFaceToFaceFeedbackList", params);
  }
}
