import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'miles-lms';
  constructor(private mdbSpinningPreloader: MDBSpinningPreloader, private swUpdate: SwUpdate, private router: Router) {
    this.mdbSpinningPreloader.stop();
  }
  ngOnInit() {  window.scroll({top:0,left:0,behavior:"smooth"});
  this.router.events.subscribe((res: RouterEvent) => {
    if (res instanceof NavigationEnd) {
      if(this.router.url.includes('moodle-video')){
        const elm1 = document.querySelector('body');
        elm1.classList.add('moodle-video');
      }
    }
  });
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
    // console.log(this.swUpdate.isEnabled)
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version is now available. Update to the Latest Version")) {
          window.location.reload();
        }
      });
      this.swUpdate.checkForUpdate()
    }

  }


}
